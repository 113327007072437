<template>
  <div class="pim-advanced-card">
    <div class="pim-card-head d-flex align-items-center">
      <slot name="header"></slot>
    </div>
    <div
      class="pim-card-body"
      v-dragscroll="dragScroll"
      :style="{ padding: padding }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvancedCard",
  props: {
    padding: {
      type: String,
    },
    dragScroll: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.pim-advanced-card {
  width: 100%;
  /*filter: drop-shadow(3px 3px 6px rgba(44, 62, 80, 0.4));*/
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  overflow-x: auto;
}

.pim-advanced-card .pim-card-head {
  background-color: $white;
  color: $gray_text;
  font-size: 1.1rem;
  //padding: 0 15px 0 30px;
  height: 50px;
  border-bottom: 1px solid #d4d8da;

  //@media (min-width: 992px) {
  //    padding: 0 15px 0 30px;
  //}
}

.pim-advanced-card .pim-card-body {
  padding: 0 15px;
  background-color: $white;

  @media (min-width: 992px) {
    padding: 0 20px;
  }
}
</style>

<template>
  <div class="rich-text-editor">
    <FormulateInput
      type="text-editor"
      @changed="changeInput"
      v-model="editor_value"
      :value="value"
      :label="bodyTitle"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RichTextEditor",
  data() {
    return {
      body_title: this.bodyTitle,
      words_label: this.wordsCountText,
      characters_label: this.charactersCountText,
      editor_value: "",
    };
  },
  computed: {
    ...mapState("helper", {
      current_language: "current_language",
    }),
    getValue() {
      return this.editor_value;
    },
    editableClass() {
      if (!this.editable) {
        return "not-editable";
      }
      return "";
    },
  },
  methods: {
    charactersLength(str) {
      let cont = str;

      cont = cont.replace(/<[^>]*>/g, " ");
      cont = cont.replace(/\s+/g, " ");
      cont = cont.trim();

      let tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      let text = tmp.textContent || tmp.innerText || "";
      let whitespaceEnd = text[text.length - 1] === " " ? 1 : 0;

      return cont.split("").length + whitespaceEnd;
    },
    wordsCount(str) {
      let cont = str;

      cont = cont.replace(/<[^>]*>/g, " ");
      cont = cont.replace(/\s+/g, " ");
      cont = cont.trim();

      if (cont.length === 0) {
        return 0;
      }

      return cont.split(" ").length;
    },
    helpString(valueStr) {
      let words = this.wordsCount(valueStr);
      let length = this.charactersLength(valueStr);

      return `${this.words_label} ${words}. ${this.characters_label} ${length}.`;
    },
    changeInput() {
      this.$emit("change-input", this.getValue);
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    wordsCountText: {
      type: String,
      default: "Words: ",
    },
    charactersCountText: {
      type: String,
      default: "Signs: ",
    },
    bodyTitle: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
    iconsToShow: {
      type: Array,
      default: () => [],
    },
    excludeIcons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.rich-text-editor ::v-deep .not-editable {
  pointer-events: none;
  user-select: all;
}
::v-deep .rich-text-editor {
  &_wrapper {
    /*border: 1px solid #7f8c8d;*/
    /*border-radius: 4px;*/
    position: relative;

    *:focus {
      outline: none;
    }

    .editor {
      border: 1px solid $light_gray;
      border-radius: 10px;
    }

    button {
      color: #7f8c8d;

      &.is-active {
        color: #036bad;
        background: transparent;
      }
    }

    .menubar {
      border-bottom: 1px solid $light_gray;
      padding: 5px 10px;
    }

    .editor__content {
      position: relative;
      background-color: $background;

      .ProseMirror {
        padding: 15px 20px;
      }
    }
  }

  &_help {
    bottom: 100px;
    margin: 15px 15px 0;
    text-align: right;
    font-size: 0.75rem;
    color: #7f8c8d;
  }
}
.rich-text-editor {
  position: relative;

  .rich-text-editor_content {
    display: relative;
  }
}
</style>

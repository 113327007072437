<template>
	<AdvancedCard v-dragscroll="dragScroll" padding="0" class="table-card__wrapper" v-bind:style="cardStyle">
		<template v-slot:header>
			<div class="table-card">
				<div class="table-card__thead-column"
					v-bind:key="index"
					v-for="( item, index ) in settings.header"
					v-bind:style="{ color: item.headerColor, flex: item.columnWidth }">

					{{ item.headerLabel }}
				</div>
				<div
					class="table-card__thead-btn"
					:class="{ 'no_label': !settings.actionsHeaderLabel }"
					v-bind:style="{ flex: settings.actionsWidth }"
				>{{ settings.actionsHeaderLabel }}</div>
			</div>
		</template>
		<slot>
			<div class="table-card__tbody" v-for="(dataItem,dindex) in dataArray" v-bind:key="dindex">
				<div class="item">
					<div class="item__name"
                        :class="settingsItem.dataType"
						v-bind:key="sindex"
						v-for="( settingsItem, sindex ) in settings.header"
						v-bind:style="{
							color: settingsItem.bodyColor,
							flex: settingsItem.columnWidth,
							whiteSpace: settingsItem.whiteSpace,
							fontSize: settingsItem.fontSize,
							textAlign: settingsItem.textAlign
						}"
					>
						<template v-if="
							dataItem.editable &&
							dataItem.editable.status &&
							dataItem.editable.fields.includes( settingsItem.key )
						">
							<template v-if="dataItem.editable.rich_text">
								<RichTextEditor
									v-on:change-input="richTextChanged( $event, settingsItem.key, dataItem )"
									v-bind:value="dataItem[settingsItem.key]"
								/>
							</template>
							<template v-else>
								<input
									type="text"
									class="item__name-input"
									v-on:input="inputChanged( $event, settingsItem.key, dataItem )"
									v-bind:value="dataItem[settingsItem.key]"
								>
							</template>
						</template>
						<template v-else>
							<template v-if="dataItem.previewType">
								<truncate
									type="html"
									action-class="btn btn-outline-primary truncate_btn"
									:clamp="labels.show_more"
									:less="labels.show_less"
									v-bind:truncated="dataItem.expanded"
									v-bind:length="dataItem.maxChars ? dataItem.maxChars : 100"
									v-bind:text="settingsItem.computeFn(dataItem[settingsItem.key])"
								></truncate>
							</template>
							<template v-else-if="settingsItem.dataType === 'dropdown' && dataItem[settingsItem.key]">
								<LightDropDown
									v-if="dataItem[settingsItem.key].dropdown && dataItem[settingsItem.key].dropdown.length"
									class="w-100"
									:dropdownButtonText="getDropdownTitle(dataItem, settingsItem.key)"
									:dropdownList="dataItem[settingsItem.key].dropdown"
									:emptyLablel="labels.no_data"
									:disabled="dataItem[settingsItem.key].disabled"
								/>
							</template>
                            <template
                                v-else-if="settingsItem.dataType === 'fontawesome' &&
                                dataItem[settingsItem.key] !== undefined"
                            >
                                <router-link
                                    v-if="settingsItem.computeFn(dataItem[settingsItem.key]).link"
                                    :to="settingsItem.computeFn(dataItem[settingsItem.key]).link"
                                >
                                    <font-awesome-icon
                                        :icon="settingsItem.computeFn(dataItem[settingsItem.key]).icon || 'circle'"
                                        :color="settingsItem.computeFn(dataItem[settingsItem.key]).color || 'initial'"
                                    />
                                </router-link>

                                <font-awesome-icon
                                    v-else
                                    :icon="settingsItem.computeFn(dataItem[settingsItem.key]).icon || 'circle'"
                                    :color="settingsItem.computeFn(dataItem[settingsItem.key]).color || 'initial'"
                                />
                            </template>
                            <template
                                v-else-if="settingsItem.dataType === 'button' &&
                                dataItem[settingsItem.key] !== undefined"
                            >
                                <Button
                                    v-for="(button, idx) in settingsItem.computeFn(dataItem[settingsItem.key])"
                                    :key="idx"
                                    :variant="button.variant"
                                    :action="() => button.click(dataItem._id)"
                                    :class="button.class"
                                    :size="button.size || 'sm'"
                                >
                                    {{button.label}}
								</Button>

                            </template>
							<template v-else>
								<b-link
									v-if="dataItem.link"
									:to="dataItem.link"
									v-html="settingsItem.computeFn(dataItem[settingsItem.key])"
									:style="{
										color: settingsItem.bodyColor,
									}"
								></b-link>
								<div
									v-else
									v-html="settingsItem.computeFn(dataItem[settingsItem.key])"
								></div>
							</template>
						</template>
					</div>
					<div
						class="item__btn"
						:class="{ 'no_label': !settings.actionsHeaderLabel }"
						:style="{ flex: settings.actionsWidth, justifyContent: settings.actionsAlign || 'flex-end' }"
					>
						<CircleButton
							v-bind:id="`table-card-btn_${aindex}_${dataItem._id}`"
							v-bind:key="aindex + actionsItem.bgColor"
							v-for="( actionsItem, aindex ) in dataItem.actions"
							v-bind:color="actionsItem.color"
							v-bind:background_color="actionsItem.bgColor"
							v-bind:icon="actionsItem.icon"
							v-bind:width="actionsItem.width"
							v-bind:height="actionsItem.height"
							v-bind:font_size="actionsItem.fontSize"
							v-bind:line_height="actionsItem.lineHeight"
							v-bind:margin="actionsItem.margin"
							v-bind:link="actionsItem.link( dataItem._id)"
							v-bind:titleAttribute="actionsItem.titleAttribute"
							v-on:click.native="actionsItem.action( dataItem._id )"
							:disabled="actionsItem.disabled || false"
						/>
					</div>
				</div>
			</div>
		</slot>
	</AdvancedCard>
</template>

<script>
	import AdvancedCard from "@/components/Cards/AdvancedCard";
	import RichTextEditor from "@/components/TextEditor/RichTextEditor";
	import CircleButton from "../Buttons/CircleButton";
	import LightDropDown from '@/components/Dropdowns/LightDropDown.vue';
	import Button from "@/components/Buttons/Button";
	import truncate from 'vue-truncate-collapsed';
	import {  mapGetters } from 'vuex';

	export default {
		name: "TableCardLS",
		components: {
			RichTextEditor,
			CircleButton,
			AdvancedCard,
			truncate,
			LightDropDown,
			Button
		},
		data() {
			return {
				cardStyle: {
					'--tableWidth': this.tableWidth,
					'--tableCursor': 'inherited'
				}
			}
		},
		props: {
			settings: {
				type: Object,
				default: () => ({
					header: [],
					actions: []
				})
			},
			dataArray: {
				type: Array,
				default: () => []
			},
			tableWidth: {
				type: String,
				default: '1100px'
			},
			dragScroll: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			...mapGetters( 'helper', ['labels'] ),
		},
		methods: {
			richTextChanged( e, key, item ) {
				this.$emit( 'changed', { value: e, key: key, item: item } );
			},
			inputChanged( e, key, item ) {
				this.$emit( 'changed', { value: e.target.value, key: key, item: item } );
			},
			getDropdownTitle(item, key) {
				if (item[key]?.dropdown[item[key]?.current]?.text)
					return item[key].dropdown[item[key].current].text + '';

				if (item[key]?.current)
					return item[key].current + '';

				return this.labels.not_set;
			},
		},
		mounted() {

			if ( this.dragScroll ) this.cardStyle['--tableCursor'] = 'grab';
		}
	}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.table-card__wrapper {
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: $scrollbar_height;
	}
	&::-webkit-scrollbar-track {
		background: $light_gray;
		-webkit-border-radius: 0 0 4px 4px;
		-moz-border-radius: 0 0 4px 4px;
		border-radius: 0 0 4px 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $navy;
		-webkit-border-radius: 0 0 4px 4px;
		-moz-border-radius: 0 0 4px 4px;
		border-radius: 0 0 4px 4px;
	}
	::v-deep .pim-card-head,
	::v-deep .pim-card-body {
		min-width: var(--tableWidth);
	}
}

.table-card {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;

	&__thead-column,
	&__thead-btn {
		display: flex;
		align-items: center;
		line-height: 1;
		padding: 0 20px;
		width: 100%;
		height: 100%;
		font-size: 0.875rem;

		//&:first-of-type {
		//	padding-left: 0;
		//}

		//@media (min-width: 1400px) {
		//	font-size: 1.12rem;
		//	padding: 0 30px;
		//}
	}

	&__thead-column {
		flex: 1 1 18%;

		&:not(:nth-last-child(2)) {
			border-right: 1px solid $light_gray;
		}
	}

	&__thead-btn {
		flex: 1 1 10%;
		padding-right: 0;
	}

	&__tbody {
		//padding: 0 15px 0 30px;
		//min-height: 50px;
		border-bottom: 1px solid $light_gray;

		//@media (min-width: 992px) {
		//	min-height: 58px;
		//}

		&.last,
		&:last-of-type,
		&:last-child {
			border-bottom: none;
		}

		.item {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.item__name {
				flex: 1 1 18%;
				display: flex;
				overflow-x: auto;
				padding: 5px 20px;
                min-height: 60px;

                .btn-sm {
                    padding: 0.25rem 0.5rem;
                    font-size: 0.875rem;
                    line-height: 1.5;
                    border-radius: 0.2rem;
                }

				&-wrapper {
					display: flex;
					align-items: center;
				}

				::v-deep a {
					color: $navy;
				}

				&:not(:nth-last-child(2)) {
					border-right: 1px solid $light_gray;
				}

				@media (min-width: 1400px) {
					padding: 7px 20px;
				}

				::v-deep {
					.rich-text-editor {
						flex: 0 0 100%;
						max-width: 100%;
						width: 100%;
					}
				}
			}

			.item__btn {
				flex: 0 0 10%;
				display: flex;
				align-items: center;
				overflow-x: auto;
				padding: 14px 15px 14px 5px;

				& > div:last-child {
					::v-deep a {
						margin-right: 0!important;
					}
				}
			}

			.item__name,
			.item__btn {
				&::-webkit-scrollbar {
					height: $scrollbar_height;
				}

				&::-webkit-scrollbar-track {
					background: $light_gray;
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					border-radius: 5px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $navy;
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					border-radius: 5px;
				}
			}
		}

		.item__name {
			::v-deep .truncate_btn {
				font-size: 0.875rem;
				padding: 0.45rem 1.8rem;
			}

			::v-deep p{
				margin-bottom: 0!important;
			}
		}

		&:last-child {
			.table-card__tbody-row {
				border-bottom: 0;
			}
		}

		& + .table-card__xactions {
			::v-deep .xform-actions {
				margin-top: 0;
			}
		}
	}

	&__xactions {
		padding: 20px 20px 40px!important;
		margin-bottom: -20px!important;
		box-shadow: 3px 3px 5px -5px rgb(0 0 0 / 30%)!important;

		::v-deep {
			.xform-actions {
				border: none;
				margin-top: -20px;

				&::before {
					display: none;
				}
			}
		}
	}
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdvancedCard',{directives:[{name:"dragscroll",rawName:"v-dragscroll",value:(_vm.dragScroll),expression:"dragScroll"}],staticClass:"table-card__wrapper",style:(_vm.cardStyle),attrs:{"padding":"0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-card"},[_vm._l((_vm.settings.header),function(item,index){return _c('div',{key:index,staticClass:"table-card__thead-column",style:({ color: item.headerColor, flex: item.columnWidth })},[_vm._v(" "+_vm._s(item.headerLabel)+" ")])}),_c('div',{staticClass:"table-card__thead-btn",class:{ 'no_label': !_vm.settings.actionsHeaderLabel },style:({ flex: _vm.settings.actionsWidth })},[_vm._v(_vm._s(_vm.settings.actionsHeaderLabel))])],2)]},proxy:true}])},[_vm._t("default",function(){return _vm._l((_vm.dataArray),function(dataItem,dindex){return _c('div',{key:dindex,staticClass:"table-card__tbody"},[_c('div',{staticClass:"item"},[_vm._l((_vm.settings.header),function(settingsItem,sindex){return _c('div',{key:sindex,staticClass:"item__name",class:settingsItem.dataType,style:({
							color: settingsItem.bodyColor,
							flex: settingsItem.columnWidth,
							whiteSpace: settingsItem.whiteSpace,
							fontSize: settingsItem.fontSize,
							textAlign: settingsItem.textAlign
						})},[(
							dataItem.editable &&
							dataItem.editable.status &&
							dataItem.editable.fields.includes( settingsItem.key )
						)?[(dataItem.editable.rich_text)?[_c('RichTextEditor',{attrs:{"value":dataItem[settingsItem.key]},on:{"change-input":function($event){return _vm.richTextChanged( $event, settingsItem.key, dataItem )}}})]:[_c('input',{staticClass:"item__name-input",attrs:{"type":"text"},domProps:{"value":dataItem[settingsItem.key]},on:{"input":function($event){return _vm.inputChanged( $event, settingsItem.key, dataItem )}}})]]:[(dataItem.previewType)?[_c('truncate',{attrs:{"type":"html","action-class":"btn btn-outline-primary truncate_btn","clamp":_vm.labels.show_more,"less":_vm.labels.show_less,"truncated":dataItem.expanded,"length":dataItem.maxChars ? dataItem.maxChars : 100,"text":settingsItem.computeFn(dataItem[settingsItem.key])}})]:(settingsItem.dataType === 'dropdown' && dataItem[settingsItem.key])?[(dataItem[settingsItem.key].dropdown && dataItem[settingsItem.key].dropdown.length)?_c('LightDropDown',{staticClass:"w-100",attrs:{"dropdownButtonText":_vm.getDropdownTitle(dataItem, settingsItem.key),"dropdownList":dataItem[settingsItem.key].dropdown,"emptyLablel":_vm.labels.no_data,"disabled":dataItem[settingsItem.key].disabled}}):_vm._e()]:(settingsItem.dataType === 'fontawesome' &&
                                dataItem[settingsItem.key] !== undefined)?[(settingsItem.computeFn(dataItem[settingsItem.key]).link)?_c('router-link',{attrs:{"to":settingsItem.computeFn(dataItem[settingsItem.key]).link}},[_c('font-awesome-icon',{attrs:{"icon":settingsItem.computeFn(dataItem[settingsItem.key]).icon || 'circle',"color":settingsItem.computeFn(dataItem[settingsItem.key]).color || 'initial'}})],1):_c('font-awesome-icon',{attrs:{"icon":settingsItem.computeFn(dataItem[settingsItem.key]).icon || 'circle',"color":settingsItem.computeFn(dataItem[settingsItem.key]).color || 'initial'}})]:(settingsItem.dataType === 'button' &&
                                dataItem[settingsItem.key] !== undefined)?_vm._l((settingsItem.computeFn(dataItem[settingsItem.key])),function(button,idx){return _c('Button',{key:idx,class:button.class,attrs:{"variant":button.variant,"action":function () { return button.click(dataItem._id); },"size":button.size || 'sm'}},[_vm._v(" "+_vm._s(button.label)+" ")])}):[(dataItem.link)?_c('b-link',{style:({
										color: settingsItem.bodyColor,
									}),attrs:{"to":dataItem.link},domProps:{"innerHTML":_vm._s(settingsItem.computeFn(dataItem[settingsItem.key]))}}):_c('div',{domProps:{"innerHTML":_vm._s(settingsItem.computeFn(dataItem[settingsItem.key]))}})]]],2)}),_c('div',{staticClass:"item__btn",class:{ 'no_label': !_vm.settings.actionsHeaderLabel },style:({ flex: _vm.settings.actionsWidth, justifyContent: _vm.settings.actionsAlign || 'flex-end' })},_vm._l((dataItem.actions),function(actionsItem,aindex){return _c('CircleButton',{key:aindex + actionsItem.bgColor,attrs:{"id":("table-card-btn_" + aindex + "_" + (dataItem._id)),"color":actionsItem.color,"background_color":actionsItem.bgColor,"icon":actionsItem.icon,"width":actionsItem.width,"height":actionsItem.height,"font_size":actionsItem.fontSize,"line_height":actionsItem.lineHeight,"margin":actionsItem.margin,"link":actionsItem.link( dataItem._id),"titleAttribute":actionsItem.titleAttribute,"disabled":actionsItem.disabled || false},nativeOn:{"click":function($event){return actionsItem.action( dataItem._id )}}})}),1)],2)])})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a
    v-if="link.length"
    class="card-arrow"
    :style="style"
    :href="link"
    :target="linkTarget"
  >
    <font-awesome-icon class="right-arrow" :icon="icon" />
  </a>
  <div v-else class="card-arrow" :style="style">
    <font-awesome-icon class="right-arrow" :icon="icon" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight);
const colors = require("/colors.config.json");

export default {
  name: "CircleButton",
  props: {
    link: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "_self",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    bgColor: {
      type: String,
      default: "#b14126",
    },
    color: {
      type: String,
      default: `${colors.white}`,
    },
    fontSize: {
      type: String,
      default: "22px",
    },
    fontWeight: {
      type: String,
      default: "400",
    },
    height: {
      type: String,
      default: "36px",
    },
    width: {
      tyoe: String,
      default: "36px",
    },
    padding: {
      type: String,
      default: "0",
    },
    icon: {
      type: String,
      default: "chevron-right",
    },
  },
  computed: {
    style() {
      return {
        "--background-color": this.bgColor,
        "--color": this.color,
        backgroundColor: this.bgColor,
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        height: this.height,
        width: this.width,
        padding: this.padding,
        color: this.color,
        ...this.styles,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 15px;
  cursor: pointer;

  // &:hover {
  //     color: var(--background-color)!important;
  //     background-color: var(--color)!important;
  // }

  svg {
    width: 50%;
  }
}
</style>

<template>
  <div
    :style="styles"
    class="light-dropdown"
    :id="`light-dropdown__${id}`"
    :ref="`light-dropdown__${id}`"
  >
    <div
      :class="[
        'light-dropdown__header',
        { disabled: disabled, 'justify-content-end': disableTitle },
      ]"
      :id="`light-dropdown__header__${id}`"
      :ref="`light-dropdown__header__${id}`"
      @click="[toggleDropDown(), getPosition()]"
    >
      <div v-if="!disableTitle" class="light-dropdown__header-title">
        {{ dropdownButtonText }}
      </div>

      <font-awesome-icon
        class="light-dropdown__header-icon"
        icon="caret-down"
      />
    </div>

    <div
      :class="['light-dropdown__body', { active: active }]"
      :id="`light-dropdown__body__${id}`"
      :ref="`light-dropdown__body__${id}`"
    >
      <template v-if="dropdownList.length">
        <div class="light-dropdown__body-list">
          <div
            class="list-item"
            v-for="(item, index) in dropdownList"
            :key="index"
            @click="active = false"
            :class="{ item_btn: item.type === 'button' }"
          >
            <template v-if="item.action">
              <b-link
                :class="item.type === 'button' ? 'btn btn-outline-primary' : ''"
                @click="item.action(item.key)"
                >{{ item.text }}</b-link
              >
            </template>
            <template v-else>
              <b-link
                :class="item.type === 'button' ? 'btn btn-outline-primary' : ''"
                :to="item.link"
                >{{ item.text }}</b-link
              >
            </template>
          </div>
        </div>
      </template>

      <template v-else-if="emptyLabel">
        <div class="selectable-empty">{{ emptyLabel }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightDropDown",
  props: {
    id: {
      type: String,
      default: "",
    },
    dropdownButtonText: {
      type: String,
      default: "",
    },
    dropdownList: {
      type: Array,
      default: () => [],
    },
    emptyLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    headerPadding: {
      type: String,
      default: "5px 10px",
    },
    disableTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      styles: {
        "--header-padding": this.headerPadding,
      },
    };
  },
  methods: {
    toggleDropDown() {
      if (!this.disabled) {
        this.active = !this.active;
      }
    },

    closeDropDown(e) {
      let element = this.$refs[`light-dropdown__${this.id}`];
      if (element && !element.contains(e.target)) {
        this.active = false;
      }
    },

    getPosition() {
      let dropdown_header = this.$refs[`light-dropdown__header__${this.id}`];
      let dropdown_body = this.$refs[`light-dropdown__body__${this.id}`];
      dropdown_body.style.width = dropdown_header.clientWidth + "px";

      let bottomDifference =
        window.innerHeight - dropdown_header.getBoundingClientRect().bottom;
      let topDifference = dropdown_header.getBoundingClientRect().top;
      if (bottomDifference < 100) {
        if (bottomDifference < topDifference) {
          if (topDifference < 350 /* dropdown body height */) {
            let header_height = dropdown_header.clientHeight;
            dropdown_body.style.visibility = "hidden";
            dropdown_body.style.height = "max-content";
            dropdown_body.style.maxHeight =
              topDifference - header_height + "px";
            setTimeout(() => {
              let body_height = dropdown_body.clientHeight;
              dropdown_body.style.top = topDifference - body_height + "px";
              dropdown_body.style.visibility = "visible";
            }, 50);
          } else {
            dropdown_body.style.visibility = "hidden";
            dropdown_body.style.height = "max-content";
            dropdown_body.style.maxHeight = "350px";
            setTimeout(() => {
              let body_height = dropdown_body.clientHeight;
              dropdown_body.style.top =
                topDifference - body_height /* dropdown body height */ + "px";
              dropdown_body.style.visibility = "visible";
            }, 50);
          }
        } else {
          dropdown_body.style.height = "max-content";
          dropdown_body.style.maxHeight = bottomDifference - 23 + "px";
        }
      } else {
        dropdown_body.style.top = `${
          dropdown_header.getBoundingClientRect().bottom
        }px`;
        if (bottomDifference < 350) {
          dropdown_body.style.height = "max-content";
          dropdown_body.style.maxHeight =
            bottomDifference - 24 /* footer height */ + "px";
        }
      }
    },
  },
  watch: {
    active() {
      if (this.active) {
        document.addEventListener("click", this.closeDropDown);
        document.addEventListener("scroll", this.closeDropDown);
      } else {
        document.removeEventListener("click", this.closeDropDown);
        document.removeEventListener("scroll", this.closeDropDown);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.light-dropdown {
  border-radius: 5px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    color: $light_text;
    padding: var(--header-padding);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid $light_gray;
    cursor: pointer;
    min-height: 33px;

    &.disabled {
      cursor: not-allowed;
    }

    // &-title {}

    &-icon {
      color: $gray_text_3;
    }
  }

  &__body {
    display: none;
    position: fixed;
    z-index: 1000000;
    background-color: $white;
    box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 10%);
    border-radius: 0px 0.25rem;
    color: $gray_text_2;
    max-height: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: $scrollbar_width;
    }

    &::-webkit-scrollbar-track {
      background: $light_gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $navy;
    }

    &.active {
      display: block;
    }

    &-list {
      .list-item {
        // padding: 8px 20px;
        // cursor: pointer;
        &.item_btn {
          padding: 8px 20px;
        }

        &:hover {
          background-color: $background;
        }

        a {
          display: block;
          color: $light_text;
          text-decoration: none;
          padding: 8px 20px;
          cursor: pointer;
        }

        a.btn {
          color: $navy;
          margin: 0;
          display: block;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
</style>
